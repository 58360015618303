<template>
    <!-- Services section start -->
	<section id="services-section" class="px-12 lg:px-32 py-16 relative overflow-hidden">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                NUESTROS SERVICIOS
            </h1>
            <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
                Potenciamos tu éxito tecnológico: asesoría, proyectos innovadores, transformación de procesos y soluciones integrales con nuestro producto de gestión de procesos.
            </p>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Services start -->
        <div class="flex flex-col md:flex-row md:gap-8">
            <!-- Consulting service start -->
            <div class="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start my-8 md:w-1/3">
                <img src="@/assets/services/consulting.png" alt="Consulting" class="-z-50 md:mb-4">
                <div class="w-2/3 text-right md:text-left md:w-full">
                    <h1 class="uppercase text-2xl font-semibold">
                        Asesoría Estratégica en Tecnología
                    </h1>
                    <p class="capitalize text-stone-500">
                        Guiamos tu empresa hacia la excelencia tecnológica, analizando tus necesidades y proponiendo soluciones personalizadas que impulsen tu crecimiento.
                    </p>
                </div>
            </div>
            <!-- Consulting service end -->

            <!-- Infrastructure service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-2/3 text-left md:w-full md:order-2">
                    <h1 class="uppercase text-2xl font-semibold">
                        Desarrollo e Infraestructura Avanzada
                    </h1>
                    <p class="capitalize text-stone-500">
                        Creamos soluciones a medida, optimizando tus procesos con tecnología de vanguardia y asegurando una infraestructura robusta y escalable para un rendimiento óptimo.
                    </p>
                </div>
                <img src="@/assets/services/infrastructure.png" alt="Infrastructure" class="-z-50 md:mb-4 md:order-1">
            </div>
            <!-- Infrastructure service end -->

            <!-- Efficient service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-1/3">
                    <img src="@/assets/services/efficient.png" alt="Efficient" class="-z-50 md:mb-4">
                </div>
                <div class="w-2/3 text-right md:text-left md:w-full">
                    <h1 class="uppercase text-2xl font-semibold">
                        Cambio Digital y Gestión Eficiente
                    </h1>
                    <p class="capitalize text-stone-500">
                        Revolucionamos tus operaciones mediante la implementación de nuestro producto de gestión de procesos, mejorando la eficiencia, la colaboración y la toma de decisiones en toda la organización.
                    </p>
                </div>
            </div>
            <!-- Efficient service end -->
        </div>
        <!-- Services end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 -right-20 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Services section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>