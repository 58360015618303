<template>
    <!-- Portfolio section start -->
	<section id="portfolio-section" class="px-4 lg:px-8 py-16  relative">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                PRODUCTOS
            </h1>
            <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
                "Transformamos ideas en innovación, incluso cuando los proyectos son pocos. ¡Descubre lo que podemos crear juntos!"
            </p>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        
        <!-- Form start -->
        <div class="grid grid-cols-1 md:grid-cols-2 w-full">
            <div class="hidden md:flex justify-center items-center">
                <img src="@/assets/kordo.png" alt="">
            </div>
            <div class="flex flex-col w-full items-center">
                <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
                    "Kordo: Tu socio en la gestión de procesos. Nuestro software, el gestor de procesos que pertenece a todos los negocios, te brinda trazabilidad total y un registro detallado en cada paso, impulsando la eficiencia y el control en tu empresa."
                </p>

                <a href="https://kordo.koherencia.com.co/" target="_blank">
                    <button class="btn w-full">Conoce Kordo</button>
                </a>
            </div>
        </div>
        <!-- Form end -->

        <!-- "More" link start --><!--
        <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>Show more photos </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a>
        --><!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Portfolio section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>