<template>
    <!-- Contact section start -->
	<section id="contact-section" class="px-12 lg:px-32 py-16 relative">
        <!-- Main text start -->
		<h1 class="uppercase text-5xl mb-4 font-semibold ">
			Contacto
		</h1>
		<!-- Main text end -->

        <!-- Form start -->
        <div class="grid grid-cols-1 md:grid-cols-2 w-full">
            <div class="hidden md:flex justify-center items-center">
                <img src="@/assets/mail.png" alt="">
            </div>
            <div class="flex flex-col w-full items-center">
                <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
                    ¡Contáctanos para llevar tus ideas tecnológicas a la realidad! Somos una empresa de software dedicada a crear soluciones innovadoras para tus proyectos tecnológicos. Estamos aquí para escuchar tus necesidades y transformarlas en resultados tangibles. ¡Esperamos trabajar contigo!
                </p>

                <a href="mailto:contacto@koherencia.com.co">
                    <button class="btn w-full">Escribenos</button>
                </a>
            </div>
        </div>
        <!-- Form end -->

        <!-- Circle start -->
        <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute -top-20 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Contact section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>