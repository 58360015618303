<template>
    <!-- About section start -->
	<section id="about-section" class="px-12 lg:px-32 py-40 border-t border-stone-500 relative">
        <!-- Main text start -->
		<h1 class="uppercase text-5xl mb-4 font-semibold ">
			NOSOTROS
		</h1>
		<p class="capitalize xl:w-1/2 text-stone-500 mb-8">
			Koherencia es un proveedor líder de productos y servicios innovadores. Estamos dedicados a ayudar a nuestros clientes a lograr el éxito y alcanzar sus metas. Nos esforzamos por brindar un servicio al cliente de primer nivel, y nuestro equipo de expertos está siempre disponible para brindar asistencia. ¡Tenemos años de experiencia y un historial comprobado de éxito, por lo que puede estar seguro de que está en buenas manos con Koherencia!
		</p>
		<!-- Main text end -->

        <!-- "More" link start --><!--
        <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>Read more </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a>
        --><!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- About section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>